import * as React from 'react';
import png from './logo_1_valge.png';

export default () => {
  return (
      <div
          onClick={() => location.href = 'https://web.peatus.ee'}
          className="logo"
          id={'logo'}
          style={{ backgroundImage: `url(${png})`,
              backgroundPosition: '-9px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              minHeight: '70%',
              minWidth: '9em',
              cursor: 'pointer'}}
      />
  );
};
